<template>
<div class="new-component">
    <header class="container container-add">
        <!-- PC端代码 -->
        <div class="d-none d-lg-block">
            <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <div class="container-fluid">
                    <router-link class="navbar-brand" to="/">
                        <img src="../assets/zjlogo.png" class="logo" alt="Logo">
                    </router-link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav d-flex justify-content-end">
                            <li class="nav-item dropdown">
                                <router-link class="nav-link" :class="{ active: activeTab === 'home' }" to="/">{{ $t('home') }}</router-link>
                            </li>

                            <li class="nav-item dropdown" @mouseover="showDropdown2 = true" @mouseleave="showDropdown2 = false">
                                <router-link class="nav-link" :class="{ active: activeTab === 'about' }" :to="{ name: 'AboutView' }" @click.native="goToAbout">{{ $t('about') }}</router-link>

                                <ul v-show="showDropdown2" class="dropdown-menu show">
                                    <li>
                                        <router-link class="dropdown-item" to="/about"> {{ $t('jianjie') }}</router-link>
                                    </li>
                                    <li>
                                        <router-link class="dropdown-item" :to="{ path: '/about', hash: '#profile' }">{{ $t('jiazhi') }}</router-link>
                                    </li>
                                    <li>
                                        <router-link class="dropdown-item" to="/History">{{ $t('licheng') }}</router-link>
                                    </li>
                                    <li>
                                        <router-link class="dropdown-item" to="/ESG">{{ $t('shehui') }}</router-link>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item" v-if="currentLanguage !== 'en'">
                                <router-link class="nav-link" :class="{ active: activeTab === 'news' }" to="/PressCenter">新闻中心</router-link>
                            </li>
                            <li class="nav-item dropdown" @mouseover="showDropdown = true" @mouseleave="showDropdown = false">
                                <!-- <router-link class="nav-link" :class="{ active: activeTab === 'other' }" to="/industry">{{ $t('buju') }}</router-link> -->
                                <router-link class="nav-link" :class="{ active: activeTab === 'other' }" to="">{{ $t('buju') }}</router-link>
                                <ul v-if="showDropdown" class="dropdown-menu show">

                                    <li>
                                        <router-link class="dropdown-item" to="/appLayout"> {{ $t('zhizhao') }}</router-link>
                                    </li>
                                    <li>
                                        <router-link class="dropdown-item" to="/industry">{{ $t('qitalei') }}</router-link>
                                    </li>
                                    <!-- <li>
                                    <router-link class="dropdown-item" to="/realEstate"> {{ $t('dichanye') }}</router-link>
                                </li> -->
                                    <!-- <li>
                                    <router-link class="dropdown-item" to="/PageLayout"> {{ $t('peitao') }}</router-link>
                                </li>
                                 <li>
                                    <router-link class="dropdown-item" to="/PageLayout"> {{ $t('chuangxin') }}</router-link>
                                </li> -->
                                </ul>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" :class="{ active: activeTab === 'contact' }" to="/ContactUs">{{ $t('contact') }}</router-link>
                            </li>
                        </ul>
                        <div class="d-flex Btn-left">
                            <div class="tabBtn" :class="{ 'btnActive': currentLanguage === 'zh' }" @click="switchLanguage('zh')">中</div>
                            <div class="tabBtn" v-if="!['/PressCenter', '/PressCenterDetails'].includes($route.path)" :class="{ 'btnActive': currentLanguage === 'en' }" @click="switchLanguage('en')">EN</div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
        <!-- 平板端代码 -->
        <div class="d-none d-lg-none d-md-block">
            <nav class="navbar navbar-expand-md navbar-light bg-light">
                <div class="container-fluid">
                    <router-link class="navbar-brand" to="/">
                        <img src="../assets/zjlogo.png" class="logo" alt="Logo">
                    </router-link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav d-flex justify-content-end">
                            <li class="nav-item dropdown">
                                <router-link class="nav-link" :class="{ active: activeTab === 'home' }" to="/">{{ $t('home') }}</router-link>
                            </li>

                            <li class="nav-item dropdown" @mouseover="showDropdown2 = true" @mouseleave="showDropdown2 = false">
                                <router-link class="nav-link" :class="{ active: activeTab === 'about' }" :to="{ name: 'AboutView' }" @click.native="goToAbout">{{ $t('about') }}</router-link>

                                <ul v-show="showDropdown2" class="dropdown-menu show">
                                    <li>
                                        <router-link class="dropdown-item" to="/about"> {{ $t('jianjie') }}</router-link>
                                    </li>
                                    <li>
                                        <router-link class="dropdown-item" :to="{ path: '/about', hash: '#profile' }">{{ $t('jiazhi') }}</router-link>
                                    </li>
                                    <li>
                                        <router-link class="dropdown-item" to="/History">{{ $t('licheng') }}</router-link>
                                    </li>
                                    <li>
                                        <router-link class="dropdown-item" to="/ESG">{{ $t('shehui') }}</router-link>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item" v-if="currentLanguage !== 'en'">
                                <router-link class="nav-link" :class="{ active: activeTab === 'news' }" to="/PressCenter">新闻中心</router-link>
                            </li>
                            <li class="nav-item dropdown" @mouseover="showDropdown = true" @mouseleave="showDropdown = false">
                                <!-- <router-link class="nav-link" :class="{ active: activeTab === 'other' }" to="/industry">{{ $t('buju') }}</router-link> -->
                                <router-link class="nav-link" :class="{ active: activeTab === 'other' }" to="">{{ $t('buju') }}</router-link>
                                <ul v-if="showDropdown" class="dropdown-menu show">

                                    <li>
                                        <router-link class="dropdown-item" to="/appLayout"> {{ $t('zhizhao') }}</router-link>
                                    </li>
                                    <li>
                                        <router-link class="dropdown-item" to="/industry">{{ $t('qitalei') }}</router-link>
                                    </li>
                                    <!-- <li>
                                    <router-link class="dropdown-item" to="/realEstate"> {{ $t('dichanye') }}</router-link>
                                </li> -->
                                    <!-- <li>
                                    <router-link class="dropdown-item" to="/PageLayout"> {{ $t('peitao') }}</router-link>
                                </li>
                                 <li>
                                    <router-link class="dropdown-item" to="/PageLayout"> {{ $t('chuangxin') }}</router-link>
                                </li> -->
                                </ul>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" :class="{ active: activeTab === 'contact' }" to="/ContactUs">{{ $t('contact') }}</router-link>
                            </li>
                        </ul>
                        <div class="d-flex Btn-left">
                            <div class="tabBtn" :class="{ 'btnActive': currentLanguage === 'zh' }" @click="switchLanguage('zh')">中</div>
                            <div class="tabBtn" :class="{ 'btnActive': currentLanguage === 'en' }" @click="switchLanguage('en')">EN</div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>

        <!-- 手机端代码 -->
        <div class="d-md-none">
            <div class="addnav">
                <router-link class="navbar-brand" to="/">
                    <img src="../assets/zjlogo.png" class="logo" alt="Logo">
                </router-link>
                <button class="btn " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                <img src="../assets/menu-mobile.png" alt="" />
            </button>
                <!-- <a class="btn btn-primary" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                    <img src="../assets/menu-mobile.png" alt="" />
                </a> -->
            </div>

            

            <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button" type="button">
                                <router-link class="nav-link" to="/">
                                    <div class="mob_nav_icon">
                                        <img src="../assets/mob_nav_icon_1.png" alt="" />
                                        {{ $t('home') }}
                                    </div>
                                </router-link>
                            </button>
                        </h2>

                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <div class="mob_nav_icon">
                                    <img src="../assets/mob_nav_icon_2.png" alt="" />
                                    {{ $t('about') }}
                                </div>
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <li>
                                <router-link class="dropdown-item" to="/about"> {{ $t('jianjie') }}</router-link>
                            </li>
                            <li>
                                <router-link class="dropdown-item" :to="{ path: '/about', hash: '#profile' }">{{ $t('jiazhi') }}</router-link>
                            </li>
                            <li>
                                <router-link class="dropdown-item" to="/History">{{ $t('licheng') }}</router-link>
                            </li>
                            <li>
                                <router-link class="dropdown-item" to="/ESG">{{ $t('shehui') }}</router-link>
                            </li>
                        </div>
                    </div>
                    <div class="accordion-item" v-if="currentLanguage !== 'en'">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed">

                                <router-link class="nav-link" to="/PressCenter">

                                    <div class="mob_nav_icon">
                                        <img src="../assets/mob_nav_icon_3.png" alt="" />
                                        新闻中心
                                    </div>
                                </router-link>
                            </button>
                        </h2>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingF">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThreeF" aria-expanded="false" aria-controls="collapseThreeF">
                                <div class="mob_nav_icon">
                                    <img src="../assets/mob_nav_icon_4.png" alt="" />
                                    {{ $t('buju') }}
                                </div>
                            </button>
                        </h2>
                        <div id="collapseThreeF" class="accordion-collapse collapse" aria-labelledby="headingF" data-bs-parent="#accordionExample">

                            <li>
                                <router-link class="dropdown-item" to="/appLayout"> {{ $t('zhizhao') }}</router-link>
                            </li>
                            <li>
                                <router-link class="dropdown-item" to="/industry">{{ $t('qitalei') }}</router-link>
                            </li>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThreeff">
                            <button class="accordion-button collapsed">
                                <router-link class="nav-link" :class="{ active: activeTab === 'contact' }" to="/ContactUs">
                                    <div class="mob_nav_icon">
                                        <img src="../assets/mob_nav_icon_5.png" alt="" />
                                        {{ $t('contact') }}
                                    </div>
                                </router-link>
                            </button>
                        </h2>

                    </div>
                    <div class="d-flex Btn-left">
                        <div class="tabBtn" :class="{ 'btnActive': currentLanguage === 'zh' }" @click="switchLanguage('zh')">中</div>
                        <div class="tabBtn"  v-if="!['/PressCenter', '/PressCenterDetails'].includes($route.path)" :class="{ 'btnActive': currentLanguage === 'en' }" @click="switchLanguage('en')">EN</div>
                    </div>
                </div>
            </div>
            <!-- <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <div class="container-fluid">
                    <router-link class="navbar-brand" to="/">
                        <img src="../assets/zjlogo.png" class="logo" alt="Logo">
                    </router-link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarNav">
                        <div class="mb-nav">
                            <div class="mbNavTitle">
                                <router-link class="nav-link" :class="{ active: activeTab === 'home' }" to="/">{{ $t('home') }}</router-link>
                            </div>
                            <div class="mbNavTitle">
                                <router-link class="nav-link" :class="{ active: activeTab === 'about' }" :to="{ name: 'AboutView' }" @click.native="goToAbout">{{ $t('about') }}</router-link>
                            </div>
                            <div class="row mbrow">
                                <div class="col-6">
                                    <router-link class="dropdown-item" to="/about">{{ $t('jianjie') }}</router-link>
                                </div>
                                <div class="col-6">
                                    <router-link class="dropdown-item" :to="{ path: '/about', hash: '#profile' }">{{ $t('jiazhi') }}</router-link>
                                </div>
                                <div class="col-6">
                                    <router-link class="dropdown-item" to="/History">{{ $t('licheng') }}</router-link>
                                </div>
                                <div class="col-6">
                                    <router-link class="dropdown-item" to="/ESG">{{ $t('shehui') }}</router-link>
                                </div>
                            </div>

                            <div class="mbNavTitle" v-if="currentLanguage !== 'en'">
                                <router-link class="nav-link" :class="{ active: activeTab === 'news' }" to="/PressCenter">新闻中心</router-link>
                            </div>
                            <div class="mbNavTitle">
                                <router-link class="nav-link" :class="{ active: activeTab === 'other' }" to="">{{ $t('buju') }}</router-link>
                            </div>
                            <div class="row mbrow">
                                <div class="col-6">
                                    <router-link class="dropdown-item" to="/appLayout"> {{ $t('zhizhao') }}</router-link>
                                </div>
                                <div class="col-6">
                                    <router-link class="dropdown-item" to="/industry">{{ $t('qitalei') }}</router-link>
                                </div>
                            </div>
                            <div class="mbNavTitle">
                                <router-link class="nav-link" :class="{ active: activeTab === 'contact' }" to="/ContactUs">{{ $t('contact') }}</router-link>
                            </div>
                            <div class="d-flex Btn-left">
                                <div class="tabBtn" :class="{ 'btnActive': currentLanguage === 'zh' }" @click="switchLanguage('zh')">中</div>
                                <div class="tabBtn" :class="{ 'btnActive': currentLanguage === 'en' }" @click="switchLanguage('en')">EN</div>
                            </div>
                        </div>

                    </div>
                </div>
            </nav> -->
        </div>
    </header>
</div>
</template>

<script>
export default {
    name: 'NavBar',
    props: {
        activeTab: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            currentLanguage: this.$i18n.locale,
            showDropdown: false, // 控制下拉菜单显示
            showDropdown2: false, // 控制下拉菜单显示
        };
    },
    methods: {
        switchLanguage(lang) {
            this.$i18n.locale = lang; // 更新 i18n 的语言
            this.currentLanguage = lang; // 更新当前语言的状态
            localStorage.setItem('locale', lang); // 保存语言到 localStorage
        },
        // switchLanguage(lang) {
        //     this.$i18n.locale = lang;
        //     this.currentLanguage = lang;
        // },
        goToAbout(event) {
            if (this.$route.name !== 'AboutView') {
                window.open({
                    name: 'AboutView'
                }).catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });
            }
            event.preventDefault();
        },
    }
};
</script>

<style scoped>
.addnav{
        display: flex;
    justify-content: space-between;
    background: #fff;
    /* padding: 10px; */
    height: 75px;
    border-radius: 5px;
    line-height: 75px;
    padding: 0 18px;
    opacity: 1;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
}
.mob_nav_icon img {
    display: block;
    padding-bottom: 10px;
    margin: auto;
}

.accordion-button:not(.collapsed)::after {
    display: none;
}

.accordion-button::after {
    display: none;
}

.offcanvas.offcanvas-start,
.offcanvas.offcanvas-end {
    width: 50% !important;
    /* 覆盖宽度 */
}

.accordion-button {
    height: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    color: #333;
}

#navbarNav {
    justify-content: end;
}

.navbar-nav {
    padding-right: 19px;
    border-right: 1px solid #d8d8d8;
}

.logo {
    width: 148px;
    height: 39px;
}

.bg-light {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.8) !important;
}

.new-component {
    width: 100%;
    height: 75px;
    position: absolute;
    left: 0;
    top: 20px;
    z-index: 99;
}

.tabBtn {
    width: 24px;
    height: 24px;
    border: 1px solid #8291c1;
    color: #8291c1;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    border-radius: 4px;
    margin: 0 5px;
}

.btnActive {
    background-color: #8291c1;
    color: #fff !important;
}

.active {
    font-weight: bold;
    border-bottom: 3px solid #8291c1;
}

.nav-item {
    font-size: 16px;
    color: #3d3d3d;
}

.Btn-left {
    padding-left: 19px;
}

.nav-item {
    padding: 0 12px;
}

.dropdown-menu {
    display: none;
    position: absolute;
    background-color: white;
    border: 1px solid #d8d8d8;
    padding: 0;
    z-index: 9999999;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-item {
    padding: 10px 20px;
    font-size: 14px;
    color: #3d3d3d;
    text-align: left;
}

.dropdown-item:hover {
    background-color: #f8f9fa;
}

@media (min-width: 1200px) {
    .container-add {
        max-width: 922px !important;
        margin: auto;
    }
}

@media (min-width: 992px) {
    .container-add {
        max-width: 922px !important;
        margin: auto;
    }
}

@media (max-width: 767px) {
    .nav-tabs{
        display: flex;
        justify-self: center;
    }
    .about-bac {
        position: relative;
        z-index: 99999;
    }

    .dropdown-item {
        text-align: center;
        padding: 30px 0;
        background: #f5f5f5;
        border-bottom: 1px solid #e7e7e7;

    }

    .mb-nav {
        padding: 30px 0;
    }

    .Btn-left {
        padding: 30px;
    }

    li {
        list-style-type: none;
    }
}

@media (max-width: 1023px) {
    .container-add {
        max-width: 100% !important;
        margin: auto;
    }

    .about-bac {
        position: relative;
        z-index: 99999;
    }
}

.mbNavTitle {
    font-family: Source Han Sans;
    font-size: 24px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    font-variation-settings: "opsz"auto;
    color: #8291C1;
    padding-bottom: 20px;
}

.mbrow {
    font-size: 16px;
    font-weight: normal;
    line-height: 32px;
    letter-spacing: 0em;
    font-variation-settings: "opsz"auto;
    color: #3D3D3D;
}
</style>

<template>
  <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button v-for="(item, index) in currentKv" :key="index" type="button"
        :data-bs-target="'#carouselExampleIndicators'" :data-bs-slide-to="index" :class="{ active: index === 0 }"
        :aria-label="'Slide ' + (index + 1)" :aria-current="index === 0 ? 'true' : null"></button>
    </div>

    <div class="carousel-inner">
      <div v-for="(item, index) in currentKv" :key="index" :class="['carousel-item', { active: index === 0 }]">
        <img :src="$imgUrl + item.image" class="d-block w-100" :alt="'Slide ' + (index + 1)">
      </div>
    </div>

    <div class="swp">
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullScreenCarousel',
  data() {
    return {
      Kv: [],
      MOBILEKv: [],
      KvEn: [],
      MOBILEKvEn: [],
      currentKv: [], // 当前显示的 Kv
    };
  },
  watch: {
    '$i18n.locale': {
      immediate: true,
      handler() {
        this.loadKvData(); // 当语言改变时重新加载数据
      },
    },
  },
  mounted() {
    this.loadKvData();
    window.addEventListener('resize', this.updateKvData);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateKvData);
  },
  methods: {
    async loadKvData() {
      try {
        const paramsDesktop = { position: 'index_kv', lang: 'cn' };
        const paramsMobile = { position: 'index_kv', type: 'MOBILE', lang: 'cn' };
        const paramsDesktopEn = { position: 'index_kv', lang: 'en' };
        const paramsMobileEn = { position: 'index_kv', type: 'MOBILE', lang: 'en' };

        const [desktopResponse, mobileResponse, desktopResponseEn, mobileResponseEn] = await Promise.all([
          this.$api.get('/api/kv', { params: paramsDesktop }),
          this.$api.get('/api/kv', { params: paramsMobile }),
          this.$api.get('/api/kv', { params: paramsDesktopEn }),
          this.$api.get('/api/kv', { params: paramsMobileEn }),
        ]);

        this.Kv = desktopResponse.data.content;
        this.MOBILEKv = mobileResponse.data.content;
        this.KvEn = desktopResponseEn.data.content;
        this.MOBILEKvEn = mobileResponseEn.data.content;

        this.updateKvData(); // 数据加载后更新当前显示的 Kv
      } catch (error) {
        console.error('Failed to load data', error);
      }
    },

    updateKvData() {
      // 判断设备类型和语言，更新显示的 Kv 数据
      if (this.$i18n.locale === 'en') {
        this.currentKv = window.innerWidth < 768 ? this.MOBILEKvEn : this.KvEn;
      } else {
        this.currentKv = window.innerWidth < 768 ? this.MOBILEKv : this.Kv;
      }
    },
  },
};
</script>

<style scoped>
.carousel-control-next-icon {
  background-image: url('@/assets/r1.png');
  width: 40px;
  height: 40px;
}

.carousel-control-prev-icon {
  background-image: url('@/assets/l1.png');
  width: 40px;
  height: 40px;
}

.carousel-control-next,
.carousel-control-prev {
  top: auto;
  bottom: 20px;
}

.carousel-control-prev {
  left: auto;
  right: 0;
  padding-right: 100px;
}

.carousel-indicators button {
  background-color: #8291C1;
}

.carousel,
.carousel-inner,
.carousel-item,
.carousel-item img {
  height: 100vh;
}

.carousel-item img {
  object-fit: cover;
}
</style>

<template>
<div>

    <div class="container-fluid about-bac">
        <NavBar :activeTab="activeTab" />
        <!-- <img src="../assets/about/image@2x.png" alt="关于紫江" /> -->
    </div>
    <div class="container-fluid fixed-top-add">
        <div class="container">
            <div class="about-title">
                <div class="">
                    <div class="breadCrumb-all">
                        <div class="container breadCrumb-a">
                            <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item hover-div" @click="goToPage('')">{{ $t('home') }}</li>
                                    <li class="breadcrumb-item  hover-div" aria-current="page" @click="goToPage('about')">{{ $t('about') }}</li>
                                    <li class="breadcrumb-item" aria-current="page">{{ $t('shehui') }}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <!-- <div class="container">

    </div> -->
    <aboutAuty />

    <FooterBar />
</div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooterBar from '@/components/FooterBar.vue';
import aboutAuty from '@/components/aboutAuty.vue';


export default {
    name: 'MyDuty',
    components: {
        NavBar,
        FooterBar,
        aboutAuty,

    },
    data() {
        return {
            currentLanguage: this.$i18n.locale,
            activeTabAbout: 'home', // 默认显示的标签页
            activeTab: 'about'
            //'home', 'about', 'news', 'contact', 'other'
        };
    },
    created() {
        console.log(this.currentLanguage)

    },
    watch: {
        '$i18n.locale': function (newLocale) {
            this.currentLanguage = newLocale;
        }
    },
    methods: {
        selectTab(tabId) {
            this.activeTabAbout = tabId;
        },
        goToPage(page) {
            console.log(page);
            window.open("/" + page);
        },
    }
};
</script>

<style scoped>
.history-title {
    font-size: 50px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    font-variation-settings: "opsz"auto;
    color: #FFFFFF;
    opacity: 0.2;
}

.container-fluid {
    padding: 0;
}

.about-bac {
    background: url(@/assets/about/aboutBac.png) 0px 0px / contain no-repeat fixed;
    position: relative;
    height: 260px;
}

@media (max-width: 767px) {
    .about-bac {
        background: url(@/assets/about/aboutBac.png) center center / cover no-repeat;
        position: relative;
        height: 300px;
        width: 100%;
    }

}

.about-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #8291C1;

}

.about-title {
    padding-top: 30px;
}

.nav-item a {
    text-decoration: none;
    font-size: 16px;
    margin: 0 30px;
    font-weight: normal;
    line-height: 32px;
    letter-spacing: 0em;
    color: #3D3D3D
}

.AppLayout-title {
    font-size: 34px;
    font-weight: normal;
    line-height: normal;
    margin-top: 25px;
    display: inline-block;
    letter-spacing: 0em;
    border-bottom: 6px solid #8291C1;
}

.AppLayout-View {
    padding: 20px 0;
    font-size: 16px;
    font-weight: normal;
    line-height: 32px;
    text-align: justify;
    /* 浏览器可能不支持 */
    letter-spacing: 0em;
}

.AppLayout-View {
    background-image: url('@/assets/earthBacB.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
}

.faz {
    padding: 60px 0 30px;
}

.line {
    margin: 30px auto;
    width: 100px;
    height: 0px;
    opacity: 1;
    border-top: 6px solid #8291C1;
}

.about-view {
    font-size: 16px;
    font-weight: normal;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0em;
}

.about-view-title {
    color: #5266A5;
    padding: 20px 0 5px;
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;

}

.about-view-detaills {

    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;

    color: #3D3D3D;

}

.about-1 {
    width: 86px;
    height: 63px;

}

.about-2 {
    width: 82px;
    height: 70px;
}

.about-3 {
    width: 70px;
    height: 77px;
}

.about-all {
    text-align: center;
    padding: 30px 0;
    align-items: flex-end;
}

.history {
    width: 100%;
}

.history-view {
    font-size: 34px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;

    font-variation-settings: "opsz"auto;
    font-feature-settings: "kern"on;
    color: #FFFFFF;
}

.history-detaills {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;

    font-variation-settings: "opsz"auto;
    font-feature-settings: "kern"on;
    color: #FFFFFF;
}

.history-tag {
    width: 30px;
    height: 10px;
    background-color: #33A37A;
    margin: 10px 0 30px;
}

.history-more {
    width: 150px;
    height: 32px;
    border-radius: 33px;
    opacity: 1;
    margin-top: 10px;
    line-height: 30px;
    text-align: center;
    background: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
}

.history-all {
    position: relative;
}

.history-po {
    position: absolute;
    top: 50%;
    left: 60px;
    transform: translateY(-50%);
}

.history-po-right {
    position: absolute;
    top: 50%;
    right: 60px;
    text-align: right;
    transform: translateY(-50%);
}

.historyadd {
    margin-left: auto;
}

.historyAll {
    padding: 60px 0 18px;
}

.aboutBacA {
    background-color: #F5F5F5;
}
</style>

// src/router/index.js
import Vue from 'vue';
import Router from 'vue-router';
import index from '../views/index.vue';
import AboutView from '../views/AboutView.vue';
import ContactUs from '../views/ContactUs.vue';
import AppLayout from '../views/AppLayout.vue';
import PressCenter from '../views/PressCenter.vue';
import PressCenterDetails from '../views/PressCenterDetails.vue';
import PageLayout from '../views/PageLayout.vue';
import MyCourse from '../views/MyCourse.vue';
import MyDuty from '../views/MyDuty.vue';
import realEstate from '../views/realEstate.vue';
import industry from '../views/industry.vue';
import testAll from '../views/testAll.vue';
import pdfView from '../views/pdfView.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'index',
      component: index,
      meta: { title: '紫江集团官网' } 
    },
    {
      path: '/about',
      name: 'AboutView',
      component: AboutView,
    },
    {
      path: '/ContactUs',
      name: 'ContactUs',
      component: ContactUs,
    },
    {
      path: '/appLayout',
      name: 'AppLayout',
      component: AppLayout,
    },
    {
      path: '/PressCenter',
      name: 'PressCenter',
      component: PressCenter,
    },
    {
      path: '/PressCenterDetails',
      name: 'PressCenterDetails',
      component: PressCenterDetails,
    },
    {
      path: '/PageLayout',
      name: 'PageLayout',
      component: PageLayout,
    },
    {
      path: '/History',
      name: 'MyCourse',
      component: MyCourse,
    }, 
     {
       path: '/ESG',
       name: 'MyDuty',
       component: MyDuty,
    }, {
       path: '/realEstate',
       name: 'realEstate',
       component: realEstate,
    }, {
       path: '/industry',
       name: 'IndustryPage',
       component: industry,
    }, {
       path: '/testAll',
       name: 'testAll',
       component: testAll,
    }, {
       path: '/pdfView',
       name: 'pdfView',
       component: pdfView,
    }
    
    // 添加其他路由配置
  ],
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth' // 平滑滚动
      };
    }
    return { x: 0, y: 0 }; // 默认滚动到顶部
  }


});

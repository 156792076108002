<template>
<div class="aboutCourse">
    <div class="container">
        <!-- <div class="breadCrumb-all">
            <div class="container breadCrumb-a">
                <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">首页</li>
                        <li class="breadcrumb-item" aria-current="page">产业布局</li>
                        <li class="breadcrumb-item" aria-current="page">制造业</li>
                    </ol>
                </nav>
            </div>
        </div> -->
        <div class="container">
            <div class="AppLayoutView">
                <div class="AppLayout-title">
                    {{ currentLanguage === 'zh' ? DataView.title_cn : DataView.title_en }}
                </div>
                <div class="row">
                    <div class="col-md-8" style="padding-right: 52px;">
                        <div class=" AppLayout-View" v-html="currentLanguage === 'zh' ? DataView.content_cn : DataView.content_en"></div>
                        <!-- <div class="AppLayout-View">
                            <p>
                                紫江成立40多年来，始终以“产业报国、科教兴国”为已任，在不断推进自身可持续发展的同时，积极回报社会，为区域的繁荣发展贡献力量。<br /><br />
                                作为中国包装行业的龙头企业，在历年企业社会责任(CSR)报告基础上，紫江企业股份自2020年起，每年发布环境、社会及管治（ESG）报告，展现了企业在创新发展、绿色低碳、循环经济、社会责任等多领域的实践和成效。紫竹高新区于2016年成立紫竹社会责任联盟，其年度可持续发展报告披露了高新区推动可持续发展典范园区的建设相关工作，成效显著。<br /><br />
                                紫江历来注重对教育事业的支持。本着“感恩社会、助力教育”的初心使命，从1994年起，紫江持续15年捐资助学江西吉安的“上海紫江希望小学”；1999年，紫江在华东师范大学捐资成立“紫江教育发展基金”，并设立“紫江学者”计划；2003年起，分别资助上海交通大学、华东师范大学两所院校整建制搬迁至紫竹高新区。近年来，又通过设立“华东师大紫江公益慈善人才培养专项基金” “华东师范大学社会主义历史与文献研究院专项建设基金”“上海交通大学社会责任投资专项基金”“定向资助马桥强恕学校”等项目，为高校建设、教学研究、人才培养等多方面提供支持，积极助力学校取得更大的办学成效。<br /><br />
                            </p>
                        </div> -->
                    </div>
                    <div class="col-md-4" style="padding: 20px 0;">
                        <div class="AppLayoutView-title">

                            {{ $t('ESGRe') }}
                            <!-- {{ currentLanguage === 'zh' ? DataViewb.title_cn : DataViewb.title_en }} -->
                        </div>
                        <div class="carousel-container">
                            <div id="carouselExample" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item" :class="{ active: index === 0 }" v-for="(item, index) in DataPdf" :key="index">
                                        <img :src="$imgUrl + (currentLanguage === 'zh' ? item.img_cn : item.img_en)" class=" pdfimg" alt="...">
                                        <div class="pdftitle hover-div" @click="openPdf(item)">
                                            {{ currentLanguage === 'zh' ? item.name_cn : item.name_en }}
                                            <img class="xiaz" src="@/assets/xiaz.png" alt="下载" />
                                        </div>
                                        <div v-if="isLoading" class="loading-overlay">
                                            <div class="spinner"></div>
                                        </div>
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="aboutBacA container-fluid">
        <div class="container">
            <div class="AppLayoutView">
                <div class="AppLayoutView-title">
                    {{ currentLanguage === 'zh' ? DataViewb.title_cn : DataViewb.title_en }}
                </div>
                <div class="row" style="align-items: center;">
                    <div class="col-md-8">
                        <div class=" AppLayout-View" v-html="currentLanguage === 'zh' ? DataViewb.content_cn : DataViewb.content_en"></div>
                        <!-- <div class="AppLayout-View">
                                <p>
                                    2015年，由沈雯董事长全额捐赠的紫江公益基金会成立。上海紫江公益基金会是经上海市民政局审核批准成立的非公募基金会，创始资金为人民币2000万元，由紫江集团董事长、总裁沈雯先生全额捐赠。<br /><br />
                                    自成立以来，紫江公益基金会不仅通过“彩虹计划”“圆梦助学计划”为紫江员工及家属提供必要的资助，还致力于为公益发展和社会创新提供资源和支持，与富有远见的基金会、企业、高校、学者合作，探索公益理念普及和公益实践推广的最佳方法路径，启动了“交通大学社会责任投资专项基金”“华东师大公益慈善人才培养专项基金”等多个教育公益项目。<br /><br />
                                    2020年基金会荣获上海4A级社会组织。截至目前，基金会获得的捐赠超过人民币1.5亿元，各项捐赠支出超过1.2亿元。<br /><br />
                                </p>
                            </div> -->
                    </div>
                    <div class="col-md-4">
                        <div class="row" style="padding-bottom: 30px;">
                            <div class="col-6 col-md-6 carouselImg" v-for="(data, index) in (currentLanguage === 'zh' ? DataViewb.carousel_cn : DataViewb.carousel_en)" :key="index">
                                <img class="about-1" :src="$imgUrl + data.image" alt="" />
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'aboutAuty',

    data() {
        return {
            activeTabAbout: 0, // 默认显示的标签页
            // activeTab: 'about', // 'home', 'about', 'news', 'contact', 'other'
            tabs: [],
            isLoading: false,
            loading: false,
            pageIndex: 0,
            pageSize: 10,
            DataView: '',
            DataViewb: '',
            currentLanguage: this.$i18n.locale,
            DataPdf: ''
        };
    },
    created() {
        console.log(this.currentLanguage)
        this.getPage(4)
        this.GetPdf()
    },
    watch: {
        '$i18n.locale': function (newLocale) {
            this.currentLanguage = newLocale;
        }
    },
    methods: {
        //获取页面详细
        async getPage(typeInt) {
            const params = {
                type: typeInt // 使用传入的 typeInt 参数
            };
            try {
                const response = await this.$api.get('/api/page', {
                    params
                });
                console.log(response.data);
                this.DataView = response.data.row[2];
                this.DataViewb = response.data.row[3];

                console.log(this.DataView)
            } catch (error) {
                this.error = 'Failed to load data';
                console.error(error);
            } finally {
                console.log('Cleanup or final actions');
            }
        },
        async GetPdf() {
            try {
                const responseB = await this.$api.get('/api/annualReportPdf', {

                });
                console.log(responseB.data, '123')
                this.DataPdf = responseB.data
            } catch (error) {
                this.error = 'Failed to load data';
                console.error(error);
            } finally {
                console.log('Cleanup or final actions');
            }
        },

        openPdf(item) {
            const fileUrl = this.$imgUrl + (this.currentLanguage === 'zh' ? item.file_cn : item.file_en);

            this.isLoading = true;

            fetch(fileUrl)
                .then(response => response.blob())
                .then(blob => {
                    const downloadUrl = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.download = fileUrl.split('/').pop();
                    document.body.appendChild(link);
                    link.click();
                    URL.revokeObjectURL(downloadUrl);
                    document.body.removeChild(link);
                    this.isLoading = false;
                })
                .catch(error => {
                    console.error('下载 PDF 失败:', error);
                    this.isLoading = false;
                });
        }

        // openPdf(item) {
        //     const fileUrl = this.$imgUrl + (this.currentLanguage === 'zh' ? item.file_cn : item.file_en);
        //     let routeData = this.$router.resolve({
        //         name: 'pdfView',
        //         query: {
        //             fileUrl: fileUrl
        //         }
        //     });
        //     window.open(routeData.href, '_blank');
        //     // window.open(fileUrl, '_blank'); // 在新窗口中打开 PDF 链接
        // }

    }
};
</script>

<style scoped>
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000999;
}

.spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top-color: #fff;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
}

@keyframes spin {
    to { transform: rotate(360deg); }
}

.carousel-control-next {
    background-image: url('@/assets/right.png');
    width: 12px;
    height: 24px;
}

.carousel-control-prev {
    background-image: url('@/assets/left.png');
    width: 12px;
    height: 24px;
}

.carousel-control-next,
.carousel-control-prev {
    top: 150px;
}

.carouselImg {
    padding: 10px;
}

.carousel-inner {
    padding-top: 40px;
}

.carousel-item {
    height: 400px;
}

.history-title {
    font-size: 50px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    font-variation-settings: "opsz"auto;
    color: #FFFFFF;
    opacity: 0.2;
}

.container-fluid {
    padding: 0;
}

.xiaz {
    padding-right: 20px;
    float: right;
    padding-top: 20px;
}

.about-bac {
    background: url(@/assets/about/image@2x.png) 0px 0px / contain no-repeat fixed;
    padding-top: 161px;
    padding-bottom: 161px;
    height: 260px;
}

.about-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #8291C1;

}

.pdfimg {
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.3);
    width: 200px;
    height: 284px;
    border-radius: 10px;
}

.active {}

.pdftitle {
    font-family: Source Han Sans;
    font-size: 16px;
    font-weight: normal;
    margin: auto;
    width: 95%;
    height: 60px;
    line-height: 60px;
    color: #FFFFFF;
    position: relative;
    margin-top: -90px;
    z-index: 9;
    border-radius: 5px;
    background: #5266A5;
}

.nav-item a {
    text-decoration: none;
    font-size: 16px;
    margin: 0 30px;
    font-weight: normal;
    line-height: 32px;
    letter-spacing: 0em;
    color: #3D3D3D
}

.AppLayout-title {
    font-size: 34px;
    font-weight: normal;
    line-height: normal;
    margin-top: 25px;
    display: inline-block;
    letter-spacing: 0em;
    border-bottom: 6px solid #8291C1;
}

.AppLayout-View {
    padding: 20px 0;
    font-size: 16px;
    font-weight: normal;
    line-height: 32px;
    text-align: justify;
    /* 浏览器可能不支持 */
    letter-spacing: 0em;
}

.faz {
    padding: 60px 0 30px;
}

.line {
    margin: 30px auto;
    width: 100px;
    height: 0px;
    opacity: 1;
    border-top: 6px solid #8291C1;
}

.about-view {
    font-size: 16px;
    font-weight: normal;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0em;
}

.about-view-title {
    color: #5266A5;
    padding: 20px 0 5px;
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;

}

.about-view-detaills {

    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;

    color: #3D3D3D;

}

.about-1 {
    width: 100%;
}

.about-2 {
    width: 82px;
    height: 70px;
}

.about-3 {
    width: 70px;
    height: 77px;
}

.about-all {
    text-align: center;
    padding: 30px 0;
    align-items: flex-end;
}

.history {
    width: 100%;
}

.history-view {
    font-size: 34px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;

    font-variation-settings: "opsz"auto;
    font-feature-settings: "kern"on;
    color: #FFFFFF;
}

.history-detaills {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;

    font-variation-settings: "opsz"auto;
    font-feature-settings: "kern"on;
    color: #FFFFFF;
}

.history-tag {
    width: 30px;
    height: 10px;
    background-color: #33A37A;
    margin: 10px 0 30px;
}

.history-more {
    width: 150px;
    height: 32px;
    border-radius: 33px;
    opacity: 1;
    margin-top: 10px;
    line-height: 30px;
    text-align: center;
    background: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
}

.history-all {
    position: relative;
}

.history-po {
    position: absolute;
    top: 50%;
    left: 60px;
    transform: translateY(-50%);
}

.history-po-right {
    position: absolute;
    top: 50%;
    right: 60px;
    text-align: right;
    transform: translateY(-50%);
}

.historyadd {
    margin-left: auto;
}

.historyAll {
    padding: 60px 0 18px;
}

.aboutBacA {
    background-color: #F5F5F5;
}

.AppLayoutView-title {

    font-size: 24px;
    color: #3D3D3D;
    padding-left: 20px;
    border-left: 6px solid #8291C1;
}

.AppLayoutView {
    padding: 30px 0;
}
</style>

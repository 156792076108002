import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  en: {
    history:"History",
    historyView:'Zijiang has gone through four decades of growth since its inception.',
    historyBtn:'More',
    home: 'Home',
    about: 'About',
    aboutbtn:'More',
    aboutView:'Zijiang, founded in 1981, is a private investment holding group rooted in shanghai.<br />Zijiang is named after the river Zigang near its place of origin in the hope that it will gather strength and expand space for sustained development.',
    contact: 'Contact Us',
    buju:'Business Segments',
    bujuView:'Zijiang has two core segments: manufacturing High-Tech Zone.',
    jianjie:'About Zijiang',
    jiazhi:'Core Values',
    licheng:'History',
    rencai:'Talent Strategy',
    shehui:'Social Responsibility',
    dang:'Party, League and Trade Union',
    baozhuang1: 'Beverage Packaging',
    baozhuang:'Soft Packaging and New Materials',
    ruanbao:'Trade Logistics',
    ruanbao1: 'New Materials',
    qita:'Other Manufacturing Segments',
    yanfa:'R & D Base',
    daxue:'University Campus',
    peitao:'Supporting Area',
    chuangxin:'Innovation Investment',
    zhuzai:'Residential Development',
    zhuzai1: 'Real estate',
    jiudian:'Hotels',
    bangong:'Office Buildings',
    guanyu:'About Zijiang',
    zhizhao:'Manufacturing',
    qitalei:'High-Tech Zone',
    qita1:'Other',
    dichanye:'Real Estate',
     icp:'Copyright 2010 All rights reserved by Zijiang Holding    沪ICP备案号11028486号-1 ',
     dizhi:'No.1388, qixin road, minhang district',
     name: "Name",
     email: "Email",
     mobile: "Phone Number",
     content: "Please leave your comments*",
    history2:'Since its inception, Zijiang has traversed over four decades of entrepreneurship.',
    moreall:'More',
    ESG:'ESG',
    ESGT: 'Zijiang has prioritized "industrial service to the country, and the advancement of the nation through science and education," focusing on balancing economic and social development.',
    gaoxing: "Zizhu High- Tech Zone, founded in 2002, is China's only national high-tech zone primarily run by private enterprises and plays a key role in Shanghai's science and innovation hub.It focuses on 'ecology, culture, and technology,' innovates systems, and fosters links among campus, community, and park, aiming to lead in strategic emerging industries and provide premium industry services.Key sectors cover information software, digital media, life sciences, smart manufacturing, aviation electronics, and new energy / materials.",
    dichanyeT: "On the 20th anniversary of Zizhu's establishment in 2022, the high- tech zone achieved a fiscal total revenue exceeding 10 billion yuan, with an annual business income of 107.5 billion yuan.It ranked 9th among national high- tech zones in China, fulfilling the promise made at its inception: 'Give me twenty years, and I will deliver a city of technology.' This new tech city, home to 150,000 highly qualified individuals, is emerging as a pivotal origin of technological innovation, a source of original innovation, and a mainstay of independent innovation in Shanghai and the nation.",
    addgaox:'Zijiang Group entered the real estate sector in the 1990s, holding a first-class national qualification for real estate development. Its projects span various fields including industrial, commercial, and residential real estate.',
    contentus:'You can send any opinions, suggestions, or questions about our company or brand here.',
    contentusbtn:'Submit',
    moreadd: 'More',
    ESGRe: 'Zijiang ESG Report',
    jianjie1:''
    // 其他英文翻译
  },
  zh: {
    jianjie1: '',
    ESGRe:'紫江企业集团股份年度ESG报告',
    moreadd:'更多合作伙伴',
    contentusbtn: '提交',
    contentus: '您可以在这里发送有关我们的公司或品牌的任何意见、建议或问题。',
    addgaox: '精准、简洁、具有商务风格的翻译：紫江集团于上世纪90年代进军房产业，拥有国家房地产开发一级资质，项目开发涵盖工业地产、商业地产和住宅地产等多个领域。',
    dichanyeT: '2022年紫竹成立20年之际，高新区财政总收入突破100亿元，年营业收入1075亿元，在全国国家级高新区中综合排名第9，实现了建园时“给我二十年，给你一座科技城”的承诺。这座拥有15万高素质人群的科技新城，正成为上海乃至国家重要的科技创新出发地、原始创新策源地、自主创新主阵地。',
    ESGT:'长期以来，紫江一直以“产业报国、科教兴国”为己任，重视经济效益和社会效益的协同发展。',
    gaoxing: ' 紫竹高新区成立于2002年，是目前中国唯一一家以民营经济为主体运作的国家高新区，也是上海科创中心建设重要承载区。高新区始终以“生态、人文、科技”为发展理念，通过体制机制创新，推进校区、社区、园区“三区联动”，努力成为铂金产业服务商和战略新兴产业的引领者。主导产业包括信息软件、数字视听、生命科学、智能制造、航空电子、新能源与新材料等六大类产业。2022年紫竹成立20年之际，高新区财政总收入突破100亿元，年营业收入1075亿元，在全国国家级高新区中综合排名第9，实现了建园时“给我二十年，给你一座科技城”的承诺。这座拥有15万高素质人群的科技新城，正成为上海乃至国家重要的科技创新出发地、原始创新策源地、自主创新主阵地。',
    ESG:'企业社会责任',
    moreall: '了解更多',
    history2:'从创立至今，紫江已走过四十多年的创业历程。',
    history:"发展历程",
    historyView:'从创立至今，紫江已走过四十多年的创业历程。',
    historyBtn:'更多紫江历史',
    aboutbtn:'了解集团详情',
    aboutView:'紫江，创始于1981年，是一家根植于上海的民营投资控股集团。<br />紫江之名，取自发源地附近一条“紫港”的江流，也寓意紫江将不断汇聚力量，迈向更广阔的发展空间。',
    home: '首页',
    about: '关于紫江',
    contact: '联系我们',
    buju:'产业布局',
    bujuView:'紫江的产业分为两大支柱板块——制造业与高新区。',
    jianjie:'紫江简介',
    jiazhi:'核心价值观',
    licheng:'发展历程',
    rencai:'人才战略',
    shehui:'社会责任',
    dang:'党工团',
    baozhuang1:'饮料包装',
    baozhuang:'饮料包装',
    ruanbao:'软包及新材料',
    ruanbao1:'软包及新材料',
    qita:'其他类',
    qita1:'其他类',
    yanfa:'研发基地',
    daxue:'大学校区',
    peitao:'配套区',
    chuangxin:'创新投资',
    zhuzai:'住宅开发',
    zhuzai1:'住宅开发',
    jiudian:'酒店业',
    bangong:'办公楼宇',
    guanyu:'关于紫江',
    zhizhao:'制造业',
    qitalei:'高新区',
    dichanye:'地产业',
    icp:'Copyright 2010 All rights reserved by Zijiang Holding    沪ICP备案号11028486号-1 ',
    dizhi:'上海市闵行区七莘路1388号',
    name: "姓名*",
    email: "邮箱*",
    mobile: "电话号码*",
    content: "请留下您的意见*"
    // 其他中文翻译
  }
};

const savedLocale = localStorage.getItem('locale') || 'zh'; // 从本地存储中读取语言设置，默认为 'zh'

const i18n = new VueI18n({
  locale: savedLocale, // 使用本地存储的语言或默认语言
  messages,
});

// 监听语言切换
// function setLanguage(locale) {
//   i18n.locale = locale; // 切换语言
//   localStorage.setItem('locale', locale); // 保存用户选择的语言到本地存储
// }

// // 示例：切换到英文
// setLanguage('zh');


export default i18n;